<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
                <div
          class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
        >
          <div
            class="lotto-title d-flex flex-row justify-content-between align-items-center w-100"
          >
            <h4><i class="far fa-newspaper"></i> ประวัติเกม</h4>
          </div>
        </div>

        <b-row>
          <b-col cols="12"><label class="p-1 m-0"><i class="fas fa-calendar-alt"></i> วันที่ เริ่ม-สิ้นสุด</label></b-col>
          <b-col cols="12" md=4 style="padding-bottom:5px" @click="gamesHistory()">
            <b-form-datepicker
            v-model="date_from"
            id="datepicker-from"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            locale="th"
            placeholder="จากวันที่" size="sm"></b-form-datepicker>
          </b-col>
          <b-col cols="12" md=4 style="padding-bottom:5px" @click="gamesHistory()">
            <b-form-datepicker
            v-model="date_to"
            id="datepicker-to"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            locale="th"
            placeholder="ถึงวันที่" size="sm" ></b-form-datepicker>
          </b-col>
          <!-- <b-col cols="12" md=4 style="padding-bottom:5px">
            <b-button variant="primary" size="sm" ><i class="fas fa-search" ></i> ค้นหา</b-button>
          </b-col> -->
        </b-row>

        <!-- <b-row>
          <b-col>
            <b-table sticky-header head-variant="light" size="sm" responsive :items="tableData.items" :fields="tableData.fields"></b-table>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col>
            <div class="table-responsive">
              <table class="table table-sm table-hover table-bordered table-striped table-vcenter">
                <thead class="thead-light">
                  <tr class="text-center">
                    <th scope="col">ค่ายเกม</th>
                    <th scope="col">เงินเดิมพัน</th>
                    <th scope="col">ชนะ</th>
                    <th scope="col">เพิ่มเติม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" id="" v-for="(item, id) in gamesHistoryData" :key="id">
                    <td class="td-center">{{ item.agentGame }}</td>
                    <td class="td-right">{{ numberWithCommas(item.betamount) }}</td>
                    <td v-if="item.winamount > 0" class="text-green td-right">+{{ numberWithCommas(item.winamount) }}</td>
                    <td v-else class="text-red td-right">{{ numberWithCommas(item.winamount) }}</td>
                    <td class="td-center">
                      <b-button
                      variant="outline-primary"
                      size="sm"
                      class="py-0 px-1"
                      v-b-modal="'gameDetailModal_' + item.agentGame"
                      ><i class="far fa-eye"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-for="(item, id) in gamesHistoryData" :key="id">
      <b-modal style="height: 100%;" v-bind="{id: 'gameDetailModal_' + item.agentGame, title: 'ค่ายเกม : ' + item.agentGame }" scrollable hide-footer size="xl">
        <table class="table table-sm table-hover table-bordered table-striped table-vcenter">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col">รูป</th>
              <th scope="col">ชื่อเกม</th>
              <th scope="col">เงินเดิมพัน</th>
              <th scope="col">Valid Amount</th>
              <th scope="col">ผลชนะ</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" id="" v-for="(data, id) in item.item" :key="id">
              <td class="td-center">
                <b-img class="w-100" v-bind="{src: data.item_image}" fluid alt="Responsive image" style="max-width: 4rem;"></b-img>
                </td>
              <td class="td-center">{{ data.item_games_name }}</td>
              <td class="td-center">{{ data.item_betAmount }}</td>
              <td class="td-center">{{ data.item_validAmount }}</td>
              <td v-if="data.item_winAmount > 0" class="text-green td-right">+{{ numberWithCommas(data.item_winAmount) }}</td>
              <td v-else class="text-red td-right">{{ numberWithCommas(data.item_winAmount) }}</td>
            </tr>
          </tbody>
        </table>
        <b-button class="mt-3" variant="primary" size="sm" block @click="$bvModal.hide('gameDetailModal_' + item.agentGame)">ปิด</b-button>
      </b-modal>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as numeral from 'numeral'
export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      const today = new Date()
      this.date_from = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate())
      this.date_to = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate())
      await this.gamesHistory()
    } catch (error) {
    }
  },
  computed: {
    ...mapGetters([
      'getGames'
    ])
  },
  methods: {
    ...mapActions(['getGamesHistory']),
    numberWithCommas (x) {
      return numeral(x).format('0,0.00')
    },
    async gamesHistory () {
      console.log('gamesHistory')
      const resp = await this.getGamesHistory({
        date: {
          from: this.date_from,
          to: this.date_to
        }
      })
      // const today = new Date()
      // console.log('resp.status_code:' + resp.KINGMAKER)
      this.gamesHistoryData = resp
      // console.log('resp:' + resp)
      // console.log('today:' + (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()))
    }
  },
  data () {
    return {
      date_from: '',
      date_to: '',
      gamesHistoryData: []
    }
  }
}
</script>
<style>
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.td-right{
  text-align: right;
}
.td-center{
  text-align: center;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff0;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.btn-round {
    border-radius: 50px
 !important;
}
.btn-violet {
    background: #8b008b;
    color: white;
}
.img-cuesor-pointer {
  cursor: pointer;
}
.lightSweep {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgba(0, 0, 0, 0); */
  overflow: hidden;
  /* -webkit-filter: brightness(1.5); */
  -webkit-mask-size: 300% 200%;
  -webkit-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -webkit-transition: -webkit-mask-position;
  -webkit-animation-timing-function: linear;
  -webkit-transform: translate3d(0, 0, 0);
  /* -moz-filter: brightness(1.5); */
  -moz-mask-size: 300% 200%;
  -moz-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -moz-transition: -webkit-mask-position;
  -moz-animation-timing-function: linear;
  -moz-transform: translate3d(0, 0, 0);
  /* -o-filter: brightness(1.5); */
  -o-mask-size: 300% 200%;
  -o-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -o-transition: -webkit-mask-position;
  -o-animation-timing-function: linear;
  -o-transform: translate3d(0, 0, 0);
  /* filter: brightness(1.5); */
  mask-size: 300% 200%;
  mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  transition: -webkit-mask-position;
  animation-timing-function: linear;
  transform: translate3d(0, 0, 0);
  transition-duration: 2s;
  animation: move 4s ease-out infinite;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  word-wrap: break-word;
}

.table {
  table-layout: fixed;
  width: 100%;
  font-size: 0.7rem;
  background-color: white;
}

.table-responsive {
  overflow-x: auto;
}
</style>
